import axios from "axios";


export default class goldpetAPI {

    constructor(){
        if (process.env.NODE_ENV=="development"){
            this.connect = axios.create({baseURL: 'http://goldpetpt.dev2.goldpet.org/api/'});
            this.endpointKiosk = axios.create({baseURL: 'http://goldpetpt.dev2.goldpet.org/module/goldmobileapp'})
        }   else       {
            this.connect = axios.create({baseURL: 'https://goldpet.pt/api/'});
            this.endpointKiosk = axios.create({baseURL: 'https://goldpet.pt/module/goldmobileapp'})
        }

        this.auth = 'NFhYTkVTOUdXSVFLV0o5WENSUjlHRFpNVTdKMjFNTVc6';
    }

    checkEmailExistOrIsChecked = (request) => {
        return this.endpointKiosk.post(`kiosk`,{
            'email': request.email,
            'animaisestimacao': request.animaisEstimacao,
            'buy': request.ondeCompra
        },{
            headers: {
                Authorization: 'Basic '+this.auth,
            }
        }). then(function(response){
            console.log(response)
            return response
        })
    }


    marcarRegistoEnviado = (chave, email, id) => {
        console.log("ler lista "+chave);
        var registos = JSON.parse(localStorage.getItem(chave));
        var i;
        console.log("tentar marcar como enviado");
        for(i=0; i < registos.length; i++){
            if (registos[i].email == email){
                registos[i].enviado = id;
                console.log("marcado como enviado");
                break;
            }
        }
        //Para Eliminar um item do array
        //this.state.postList.filter(item => item.post_id != deletePostId
        localStorage.setItem(chave, JSON.stringify(registos));

    }

    async reenviarPendentes (chave) {
        console.log("A ler a lista de pendentes "+chave);
        var strRecord = await localStorage.getItem("clt_"+chave);
        console.log(strRecord);
        var registos = JSON.parse(strRecord);
        console.log(registos);
        var i;

        if (registos === null){
            console.log("Não foi obtido qualquer valor da chave "+chave);
            return true;
        }
        for(i=0; i < registos.length; i++){
            if (registos[i].enviado === false){
                console.log(i+" - Reenviar registo "+registos[i].email);
                await this.postCustomers(
                    {passwd: registos[i].passwd,
                    name: registos[i].firstname,
                    lastname:registos[i].lastname,
                    email: registos[i].email,
                    tipoCliente:chave,
                    log:false})
            }
        }
    }

    postCustomers = ({animaisEstimacao, ondeCompra, passwd,
        name, lastname, email, tipoCliente, log=true}) => {

        var key = "clt_"+tipoCliente;
        if(log){
            var registos = JSON.parse(localStorage.getItem(key));
            if (registos == null){
                console.log("instanciar nova lista");
                registos = [];
            }

            var cliente = {
                firstname: name,
                lastname: lastname,
                email: email,
                passwd: passwd,
                enviado: false
            };
            registos.push(cliente);

            localStorage.setItem(key, JSON.stringify(registos));
        }
        /*var exist = await (new goldpetAPI).checkIfRegistered(email);
        if (exist !== 0){
            console.log("Pára" + exist);
            return false;
        }   else    {
            console.log("continua" + exist);
            return false;
        }*/
       var xml = '<?xml version=\"1.0\" encoding="UTF-8"?>'+
            '<prestashop xmlns:xlink="http://www.w3.org/1999/xlink">'+
                '<customer>'+
                    `<passwd>${passwd}</passwd>`+
                    `<lastname>${lastname}</lastname>`+
                    `<firstname>${name}</firstname>`+
                    `<email>${email}</email>`+
                    `<id_default_group>3</id_default_group>`+
                    `<note>Registo na AVISAN2024 | ${animaisEstimacao} | ${ondeCompra}</note>`+
                    `<active>1</active>`+
                    '<associations> '+
                        '<groups>'+
                            '<group>'+
                                `<id>3</id>`+
                            '</group>'+
                        '</groups>'+
                    '</associations>'+
                '</customer>'+
            '</prestashop>'

        return this.connect.post('customers?output_format=JSON',xml,{
            headers: {
                Authorization: 'Basic '+this.auth,
            }
        }). then( function(response){
            if (Number.isInteger(response.data.customer.id_shop)){
                (new goldpetAPI()).marcarRegistoEnviado(key,email,response.data.customer.id);
            }
            return response;

        }).catch((error) => {
            return false;
        })
    }

    checkIfRegistered(email){

        /**
         * Verifica se o e-mail já está registado,
         * se sim regista o check em AVISAN2024.
         *
         * se o registo já tiver o check em AVISAN2024
         * return o ecrã sorry
         * **/

        var res = true;
        var output = this.connect.get('customers?output_format=JSON&filter[email]='+email,{
            headers: {
                Authorization: 'Basic '+this.auth
            }
        }).then(function(response){
            console.log(response)
            if (response.status!=200){
               return false;
            }

            if (response.data.customers === 'undefined' || response.data.customers == null){
                return 0;
            }   else    {
                return response.data.customers.length;
            }


        }).catch((error) => {
            return false;
        })


        return output;
    };
}

