import React, { useState } from "react";
import { Card, Form, Button, Alert, Row, Col, Spinner} from "react-bootstrap";
import InputLabel from "../InputLabel";
import InputConcelhos from "../InputLabel/InputConcelhos";
import Header from '../HeaderGoldpet';
import qrcode from '../../img/intro/qr-goldpet.png'

//Importaçao de estilos
import 'bootstrap/dist/css/bootstrap.css'
import './style.css'

//Importação de imagens
import { Link, Navigate, useNavigate } from "react-router-dom";
import goldpetAPI from "../../api/goldpet";
import Tools from "../../classes/Tools";
import {wait} from "@testing-library/user-event/dist/utils";

export const _ANIMALS_ = [
    'Cão', 'Gato', 'Ave', 'Peixe', 'Roedor','Réptil'
];

const _EMAIL_EXIST_ = 'EXIST';

const _EMAIL_EXIST_IS_CHECKED_ = 'IS_CHECKED';

const _EMAIL_DONT_EXIST_ = 'DONT_EXIST';

export default function ConsumidorFinal() {
    var obj;
    var timer;
    let navigate = useNavigate();
    if (process.env.NODE_ENV=="development"){
        var dev = true;
    }   else    {
        var dev = false;
    }
    clearInterval(timer);
    var offLineMode = Tools.offlineAvailable();
    const [name, setName] = useState(!dev ? "":"Tiago");
    const [apelido, setApelido] = useState(!dev ? "":"Silva");
    const [email, setEmail] = useState(!dev ? "":"tiago.silva@goldpet.pt");
    const [passwd, setPasswd] = useState(!dev ? "":"123456");
    const [formErro, setErro] = useState("");
    const [showErro, setShowErro] = useState(false);
    const [formSucesso, setSucesso] = useState("");
    const [showSucesso, setShowSucesso] = useState(false);
    const [animal, setAnimal] = useState([]);
    const [buy, setBuy] = useState([]);
    const [loading, setLoading] = useState(false)

    async function validarForm(){
        setErro("");
        if(name.trim().length<3){
            setErro("O campo nome é inválido");
            return false;
        }
        if(apelido.trim().length<3){
            setErro("O campo apelido é inválido");
            return false;
        }

        if(animal.length === 0){
            setErro("O campo animal de estimação é inválido");
            return false;
        }

        if(buy.length === 0){
            setErro("Onde compra os seus produtos?");
            return false;
        }

        if (!validacaoEmail(email)){
            setErro("O campo e-mail é inválido");
            return false;
        }

        if (passwd.length<6){
            setErro("O campo palavra-chave deve ter mais caracteres");
            return false;
        }

        /*var existeEmail = await (new goldpetAPI).checkIfRegistered(email);
        if (existeEmail===false && !offLineMode){
            setErro("Não foi possivel validar a existência do email");
            return false;
        }else if(Number.isInteger(existeEmail)){
            if (existeEmail > 0){
                setErro("O e-mail já se encontra registado");
                setShowErro(true);
                return false;
            }
        } */
        //Validar se o email já está registado !
        return true;
    }

    function validacaoEmail(field) {
        var usuario = field.substring(0, field.indexOf("@"));
        var dominio = field.substring(field.indexOf("@")+ 1, field.length);

        if ((usuario.length >=1) &&
            (dominio.length >=3) &&
            (usuario.search("@")==-1) &&
            (dominio.search("@")==-1) &&
            (usuario.search(" ")==-1) &&
            (dominio.search(" ")==-1) &&
            (dominio.search(".")!=-1) &&
            (dominio.indexOf(".") >=1)&&
            (dominio.lastIndexOf(".") < dominio.length - 1)) {
            return true;
        }else{
            return false;
        }
    }

    async function submit() {
        const valid = await validarForm();

        if (valid){
            setLoading(true)
            const request = {
                name: name,
                lastname: apelido,
                email: email,
                passwd: passwd,
                animaisEstimacao: animal,
                ondeCompra: buy,
                tipoCliente: "CFinal"
            }


            localStorage.setItem(name,JSON.stringify([name,apelido]))

            obj = new goldpetAPI();

            /** Verifica se já é cliente **/
            const {data: existedEmail, status: status} = await obj.checkEmailExistOrIsChecked(request)

            if(status === 200 && existedEmail === _EMAIL_EXIST_IS_CHECKED_){
                navigate('/SorryScreen')
                return
            }

            if(status === 200 && existedEmail === _EMAIL_EXIST_){
                navigate('/ThankYouConsumidorFinal')
                return
            }

            if(status === 200 && existedEmail === _EMAIL_DONT_EXIST_){
                const novoRegisto = await obj.postCustomers(request);
                if (novoRegisto === false){
                    setLoading(false)
                    setShowSucesso(false);
                    if (novoRegisto.errors !== undefined){
                        setErro(novoRegisto.errors[0].message);
                    }   else    {
                        if (!offLineMode){
                            setErro("Ocorreu um erro a comunicar o registo");
                        }   else    {
                            setShowSucesso(true);
                            setSucesso("Registo guardado");
                            timer=setInterval(clearForm, 1000);
                        }
                    }
                }   else    {
                    setLoading(false)
                    setShowSucesso(true);
                    setSucesso("Registo guardado com o nrº "+novoRegisto.data.customer.id);
                    timer=setInterval(clearForm, 1000);
                }
            }

        }   else    {
            setLoading(false)
            setErro('O formulário não está válido. Verifique se todos os campos estão preenchidos');
            setShowSucesso(false);
        }
    }

    function adicionaOpcaoAnimalEstimacao(option){
        if(animal.includes(option)){
            const index = animal.indexOf(option);
            animal.splice(index,1);
            setAnimal(animal)
        } else {
            setAnimal((prevState) => [
                ...prevState,
                option
            ])
        }
    }

    function adicionaOpcaoOndeComprar(option){
        if(buy.includes(option)){
            const index = buy.indexOf(option);
            buy.splice(index,1);
            setBuy(buy)
        } else {
            setBuy( (prevState) => [
                ...prevState,
                option
            ])
        }
    }

    function clearForm(){
        setErro("")
        setName("");
        setApelido("");
        setEmail("");
        setPasswd("");
        setAnimal("");
        setBuy("");
        clearInterval(timer);
        navigate("/SuccessRegistScreen");
    }

    return(
        <div className="pagegp">
                <Header local='home' />
                <Card className="formMaingp">
                    <Card.Header className="formHeadergp">
                        CRIAR CONTA CLIENTE
                    </Card.Header>
                    <Card.Body>

                        <Form className="formBody">

                            { loading && <div className="d-flex justify-content-center">
                                <Spinner animation="border" variant="warning" />
                            </div>}

                            { formErro !== '' &&
                            <Alert variant="danger" show={true}>
                                <Alert.Heading>Ocorreu um erro</Alert.Heading>
                                <p>{formErro}</p>
                            </Alert> }

                            <Alert variant="success" show={showSucesso}>
                                <Alert.Heading>Registado com sucesso</Alert.Heading>
                                <p>{formSucesso}</p>
                            </Alert>

                            <Form.Label className="label labelInline">Qual o seu animal de estimação?</Form.Label>

                            <Row className="inputList">
                                {
                                   _ANIMALS_.map((a, k) => <Col key={k}>
                                       <Form.Check
                                           className="inputform inputTipoConta animals"
                                           name="checkbox[]"
                                           type="checkbox"
                                           onChange={(e) => adicionaOpcaoAnimalEstimacao(e.target.value)}
                                           label={a}
                                           value={a}/>
                                   </Col> )
                                }
                            </Row>


                            <Form.Label className="label labelInline">Onde compra os produtos para o seu animal de estimação?</Form.Label>

                            <Row className="inputList">
                                <Col>
                                    <Form.Check
                                        className="inputform inputTipoConta animals"
                                        name="ondeCompra[]"
                                        type="checkbox"
                                        label="Loja online"
                                        onChange={(e) => adicionaOpcaoOndeComprar(e.target.value)}
                                        value="LojaOnline"/>
                                </Col>
                                <Col>
                                    <Form.Check
                                        className="inputform inputTipoConta animals"
                                        name="ondeCompra[]"
                                        type="checkbox"
                                        label="Loja física"
                                        onChange={(e) => adicionaOpcaoOndeComprar(e.target.value)}
                                        value="LojaFisica"/>
                                </Col>
                            </Row>





                            <InputLabel label="Nome Próprio" variable={name} funcao={e => setName(e.target.value)}/>
                            <InputLabel label="Apelido" variable={apelido} funcao={e => setApelido(e.target.value)} />
                            <InputLabel label="E-mail" tipo="email" variable={email} funcao={e => setEmail(e.target.value)}/>
                            <InputLabel label="Palavra-chave" variable={passwd} tipo="password" funcao={e => setPasswd(e.target.value)}/>

                        </Form>
                        <Button variant="primary" className="inputform inputsubmitgp" onClick={submit}>Guardar</Button>{' '}
                    </Card.Body>
                </Card>
            <Link to="/">
                <div className="overlaygp">
                </div>
            </Link>
        </div>
    )
}
